


























































































































































import { Component, Vue } from "vue-property-decorator";
import { WhatsNew } from "@/views/WhatsNew.vue";
import { _onRegistrationDispatcher, wb } from "./registerServiceWorker";
import { EventHandler } from "./event";
import { createClient } from "@/helpers/graphql-ws-rtc-adapter";
const Subscriber = require("@jetblack/graphql-reconnect-client");

@Component({
  components: { WhatsNew },
})
export default class App extends Vue {
  messages = [];
  name: string = "App";
  navigationDrawer: boolean = false;
  activeNotices: any[] = [];

  beforeCreate() {
    this.$store.commit("snackbarHide");
    this.$store.commit("popup/hide");
  }

  handle: EventHandler<ServiceWorkerRegistration> = (registration: ServiceWorkerRegistration) => {
    if (wb) {
      console.log("Workbox...");
      wb.addEventListener("waiting", async () => {
        console.log("Update is ready, waiting");
        this.$store.commit("popup/displayYesNo", {
          message: "An update is available. Would you like to update?",
          yesAction: async () => {
            if (wb) {
              await wb.messageSW({ type: "SKIP_WAITING" });
              window.location.reload();
            } else throw Error("No wb to messageSW SKIP_WAITING");
          },
        });
      });
    } else console.log("NO WORKBOX!");
  };

  async created() {
    console.log("App started...", status);
    _onRegistrationDispatcher.register(this.handle);
  }

  mounted() {
    this.$vuetify.theme.dark = this.$store.getters["dark"]();
    Notification.requestPermission(function (status) {
      console.log("Notification permission status:", status);
    });
    this.$store.dispatch(
      "user/addFirebaseCallback",
      setTimeout(() => {
        this.getNotificationsSocket();
      }, 10000)
    );

  }

  toggleLightDarkMode() {
    this.$store.commit("lightDarkMode", this.$store.state.lightDarkMode === "light" ? "dark" : "light");
    this.$vuetify.theme.dark = this.$store.getters["dark"]();
  }
  destroyed() {
    if (this.graphQLUnsubscribe) {
      this.graphQLUnsubscribe();
    }
    if (this.graphQLSubscriber) {
      this.graphQLSubscriber();
    }
  }
  signOut() {
    this.$store.dispatch("user/signOut");
  }
  dialog = false;
  items = [
    { title: "Crib Readings", icon: "mdi-altimeter", link: "crib" },
    { title: "Feed Planning", icon: "mdi-table-large", link: "plan" },
    { title: "Mix Schedule", icon: "mdi-table-large", link: "mix" },
    { title: "Feeding Schedule", icon: "mdi-nutrition", link: "schedule" },
    { title: "Feed Delivery", icon: "mdi-tractor", link: "delivery" },
    { title: "Map", icon: "mdi-map", link: "map" },
    { title: "Farm Setup", icon: "mdi-barn", link: "setup" },
    { title: "Options", icon: "mdi-cogs", link: "options" },
    { title: "Notifications", icon: "mdi-message", link: "notifications" },
    { title: "Reports", icon: "mdi-file-chart", link: "reports" },
  ];

  subscriptions = `subscription($guid: String!, $user: String!) {
         activeNotifications(guid: $guid, user:$user) {
          typename
          guid
          userId
          notifiedCount
          message
          conditions{
            typename
            guid
            fieldType
            fieldGuid
            condition
          }
        }
    }`;

  graphQLSubscriber: any = undefined;
  graphQLUnsubscribe: any = undefined;

  async getNotificationsSocket(){
    if (this.$store.state.useP2PConn){
      await this.getOnlineNotificationsSocket();
    }
    else{
      await this.getOnSiteNotificationsSocket();
    }
  }

  async getOnlineNotificationsSocket() {
    let variables = { guid: this.$store.state.user.location.guid, user: this.$store.state.user.user.uid };

    const subscribe = (subscriptions: string, variables: any, callback: (error: any, data: any) => Promise<void>) => {
      const client = createClient();
      let unsubscribe = client?.subscribe(
        {
          query: subscriptions,
          variables: variables,
        },
        {
          next: (message: any) => callback(undefined, message.data),
          error: (err: any) => {
            throw err;
          },
          complete: () => console.log("subscription completed"),
        }
      );
      return unsubscribe;
    };

    this.graphQLUnsubscribe = subscribe(this.subscriptions, variables, async (error: any, data: any) => {
      this.$store.state.wsConnected = true;
      if (!(error || subscribe)) {
        console.log("subscribe success");
        this.$store.state.wsDialogVisible = false;
        // Normal closure
        return;
      }
      if (error) {
        console.log("error subscribing");

        this.$store.state.wsConnected = false;
        if (error.event.type === "close") {
        } else {
          this.$store.state.wsDialogVisible = true;
          console.error(error);
          //    throw error;
        }
      }
      this.$store.state.wsDialogVisible = false;
      this.$store.state.wsConnected = true;
      console.log("almost there");

      console.log(data);
      this.activeNotices = data.activeNotifications;
      this.$store.commit("updateField", { path: "activeNotices", value: this.activeNotices });
    });
  }
  
  
 urlws = "wss://pi.gigalot.systems:7778/api/ws";
  options = {};
  operationName = null;
  
  async getOnSiteNotificationsSocket() {
    
  let variables = {guid:this.$store.state.user.location.guid, user: this.$store.state.user.user.uid};
    this.graphQLSubscriber = Subscriber.graphQLReconnectingSubscriber(
      this.urlws,
      this.options,
      (error: any, subscribe: any) => {
        try {
          if (!(error || subscribe)) {
            // Normal closure.
            console.log("connected to socket");
            this.$store.state.wsDialogVisible = false;
            return;
          }
          if (error) {
            console.log("error connecting to socket");
            if (error.event.type === "close") {
            } else {
              this.$store.state.wsDialogVisible = true;
              console.error(error);
            }
          }
          console.log("socket connecting ..." + this.subscriptions + JSON.stringify(variables));
          this.graphQLUnsubscribe = subscribe(this.subscriptions, variables, this.operationName, (error: any, data: any) => {
            this.$store.state.wsConnected = true;
            if (!(error || subscribe)) {
              console.log("subscribe success");
              this.$store.state.wsDialogVisible = false;
              // Normal closure
              return;
            }
            if (error) {
              console.log("error subscribing");

               this.$store.state.wsConnected = false;
              if (error.event.type === "close") {
              } else {
                this.$store.state.wsDialogVisible = true;
                console.error(error);
                //    throw error;
              }
            }
            this.$store.state.wsDialogVisible = false;
            this.$store.state.wsConnected = true;
            console.log("almost there");

            console.log(data);
            this.activeNotices = data.activeNotifications;
            this.$store.commit("updateField", { path: "activeNotices", value: this.activeNotices });
          });
        } catch (err) {
          console.log(err);
          //show offline maybe
          //this.$store.state.wsDialogVisible = true;
        }
      },
      15000,
      0,
      "graphql-ws"
    );
  }
}
